<template>
  <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
    <h1>The Cardware site is under maintenance. Please check back soon.</h1>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
};
</script>

<style scoped>

</style>
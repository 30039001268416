import { createApp } from 'vue';
import App from './App.vue';
import MaintenancePage from './MaintenancePage.vue';
import router from './router';
import './index.css';
import 'animate.css';

import VueGoogleMaps from '@fawmi/vue-google-maps';

import { createHead } from '@vueuse/head';

const head = createHead();

const isMaintenanceMode = false;

if (isMaintenanceMode) {
  const app = createApp(MaintenancePage);
  app.mount('#app');
} else {
  const app = createApp(App);

  app.use(router);

  app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: 'places',
    },
  });

  app.use(head);

  app.mount('#app');
}